/* eslint-disable react/react-in-jsx-scope */
import Login from "pages/Authentication/Login"
import Profile from "pages/Authentication/Profile"
import TeacherVerify from "pages/Authentication/TeacherVerify"
import AuthorList from "pages/Author/AuthorList"
import AuthorRegister from "pages/Author/AuthorRegister"
import BannerArea from "pages/BannerArea/BannerArea"
import BlogList from "pages/Blog/BlogList"
import CreateBlog from "pages/Blog/CreateBlog"
import BlogDetails from "pages/BlogDetails/BlogDetails"
import Coupon from "pages/Coupon"
import ManageCoupon from "pages/Coupon/ManageCoupon"
import Course from "pages/Course"
import CourseType from "pages/Course/CourseType"
import CreateCourse from "pages/Course/CreateCourse"
import CreateCourseType from "pages/Course/CreateCourseType"
import Dashboard2 from "pages/Dashboard2"
import ExamDataReview from "pages/ExamDataReview/ExamDataReview"
import Listening from "pages/ExamSet/Listening"
import CreateListeningSet from "pages/ExamSet/Listening/CreateListeningSet"
import Questions from "pages/ExamSet/Listening/Question2/Questions"
import ListeningComparison from "pages/ExamSet/Listening/Questions/Comparison.js"
import ListeningFillBlanks from "pages/ExamSet/Listening/Questions/FillBlanks.js"
import ListeningFillInTheBlanks from "pages/ExamSet/Listening/Questions/FillInTheBlanks.js"
import ListeningFillInTheBlanksOne from "pages/ExamSet/Listening/Questions/FillInTheBlanksOne"
import ListeningFillInTheBlanksThree from "pages/ExamSet/Listening/Questions/FillInTheBlanksTree"
import ListeningFillInTheBlanksTwo from "pages/ExamSet/Listening/Questions/FillInTheBlanksTwo"
import ListeningHeadMatching from "pages/ExamSet/Listening/Questions/HeadMatching.js"
import ListeningMCQ from "pages/ExamSet/Listening/Questions/MCQ.js"
import ListeningMultiple from "pages/ExamSet/Listening/Questions/Multiple.js"
import ListeningPreviewQuestion from "pages/ExamSet/Listening/Questions/PreviewQuestion.js"
import ListeningTrueFalse from "pages/ExamSet/Listening/Questions/TrueFalse.js"
import ListeningYesNoQuestion from "pages/ExamSet/Listening/Questions/YesNoQuestion.js"
import SingleListeningSet from "pages/ExamSet/Listening/SingleListeningSet"
import UploadAudio from "pages/ExamSet/Listening/UploadAudio"
import ViewQuestions from "pages/ExamSet/Listening/ViewQuestions"
import CreatePassage from "pages/ExamSet/Reading/CreatePassage"
import CreateSet from "pages/ExamSet/Reading/CreateSet"
import ListSet from "pages/ExamSet/Reading/Index"
import ListPassage from "pages/ExamSet/Reading/ListPassage"
import Questions2 from "pages/ExamSet/Reading/Question2/Questions"
import Comparison from "pages/ExamSet/Reading/Questions/Comparison"
import FillBlanks from "pages/ExamSet/Reading/Questions/FillBlanks"
import FillInTheBlanks from "pages/ExamSet/Reading/Questions/FillInTheBlanks"
import FillInTheBlanksOne from "pages/ExamSet/Reading/Questions/FillInTheBlanksOne"
import FillInTheBlanksThree from "pages/ExamSet/Reading/Questions/FillInTheBlanksThree"
import FillInTheBlanksTwo from "pages/ExamSet/Reading/Questions/FillInTheBlanksTwo"
import HeadMatching from "pages/ExamSet/Reading/Questions/HeadMatching"
import MCQ from "pages/ExamSet/Reading/Questions/MCQ"
import Multiple from "pages/ExamSet/Reading/Questions/Multiple"
import PreviewQuestion from "pages/ExamSet/Reading/Questions/PreviewQuestion"
import TrueFalse from "pages/ExamSet/Reading/Questions/TrueFalse"
import YesNoQuestion from "pages/ExamSet/Reading/Questions/YesNoQuestion"
import ViewPassage from "pages/ExamSet/Reading/ViewPassage"
import SingleDateSchedule from "pages/ExamSet/Speaking/SingleDateSchedule"
import SpeakingMain from "pages/ExamSet/Speaking/SpeakingMain"
import SpeakingTimeSlotCreate from "pages/ExamSet/Speaking/SpeakingTimeSlotCreate"
import Writing from "pages/ExamSet/Writing"
import CreateWritingSet from "pages/ExamSet/Writing/CreateSet"
import CreateAcademicQues from "pages/ExamSet/Writing/Questions/CreateAcademicQues"
import SingleWritingSet from "pages/ExamSet/Writing/SingleSet"
import FacilatorSingleDay from "pages/facilitator/FacilatorSingleDay"
import FacilitatorMain from "pages/facilitator/FacilitatorMain"
import FacilitatorPackage from "pages/facilitator/FacilitatorPackage"
import FacilitatorReview from "pages/facilitator/FacilitatorReview"
import FacilitatorSinglePackage from "pages/facilitator/FacilitatorSinglePackage"
import FacilitatorSlotList from "pages/facilitator/FacilitatorSlotList"
import FacilitatorTimeSlot from "pages/facilitator/FacilitatorTimeSlot"
import FacilitatorListeningService from "pages/facilitator/Services/FacilitatorListeningService"
// import FacilitatorReadingResult from "pages/facilitator/Services/FacilitatorReadingResult"
import FacilitatorReadingService from "pages/facilitator/Services/FacilitatorReadingService"
import FacilitatorWritingService from "pages/facilitator/Services/FacilitatorWritingService"
import SingleFacilitatorSlot from "pages/facilitator/SingleFacilitatorSlot"
import FacilitatorResult from "pages/FacilitatorResult/FacilitatorResult"
import CreateListFAQ from "pages/FAQ/CreateListFAQ"
import CreateTypeFaq from "pages/FAQ/CreateTypeFaq"
import EditFaq from "pages/FAQ/EditFaq"
import EditTypeFaq from "pages/FAQ/EditTypeFaq"
import ListFAQ from "pages/FAQ/ListFAQ"
import typeListFaq from "pages/FAQ/TypeListFaq"
import OrderList from "pages/Order/OrderList"
import CreatePackage from "pages/Package/CreatePackage"
import PackageDetails from "pages/Package/PackageDetails"
import PackageList from "pages/Package/PackageList"
import Result from "pages/Result/Result"
import ReviewYourAnswer from "pages/ReviewAnswer/Index"
import ReviewAnswer from "pages/ReviewAnswer/ReviewAnswer"
import ReviewAnswerList from "pages/ReviewAnswer/ReviewAnswerList"
import Services from "pages/Services"
import CreateService from "pages/Services/CreateService"
import Settings from "pages/Settings/Settings"
import SplashArea from "pages/SplashArea/SplashArea"
import PurchaseHistory from "pages/Student/PurchaseHistory"
import StudentList from "pages/Student/StudentList"
import UserList from "pages/Student/UserList"
import ViewProfile from "pages/Student/ViewProfile"
import ChatBox from "pages/Support/ChatBox"
import TokenList from "pages/Support/TokenList"
import Register from "pages/Teacher/Register"
import TeacherList from "pages/Teacher/TeacherList"
import ViewTeacher from "pages/Teacher/ViewTeacher"
import TeacherExam from "pages/TeacherDashboard/TeacherExam"
import TeacherExamReview from "pages/TeacherDashboard/TeacherExamReview"
import TeacherExamReviewResult from "pages/TeacherDashboard/TeacherExamReviewResult"
import TeacherSingleDay from "pages/TeacherDashboard/TeacherSingleDay"
import UniversityList from "pages/University/UniversityList"
import { Redirect } from "react-router-dom"

const openRoute = [
  { path: "/login", component: Login },
  // { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/verify-account", component: TeacherVerify },
  // { path: "/result-under-process", component: ResultUnderProcess },
]

const authRoute = [
  // eslint-disable-next-line react/react-in-jsx-scope
  {
    path: "/",
    component: () => <Redirect to="/dashboard" />,
    roles: ["admin", "teacher"],
  },
  // { path: "/dashboard", component: Dashboard, roles: ["admin", "teacher"] },
  { path: "/dashboard", component: Dashboard2, roles: ["admin", "teacher"] },
  { path: "/packages", component: PackageList, roles: ["admin"] },
  { path: "/package/:id", component: PackageDetails, roles: ["admin"] },
  { path: "/create-package", component: CreatePackage, roles: ["admin"] },
  {
    path: "/edit-package/:id",
    component: () => <CreatePackage edit />,
    roles: ["admin"],
  },

  // teacher
  { path: "/teachers-list", component: TeacherList, roles: ["admin"] },
  { path: "/register-teacher", component: Register, roles: ["admin"] },

  { path: "/teacher/:id", component: ViewTeacher, roles: ["admin"] },
  // Blog
  { path: "/author-list", component: AuthorList, roles: ["admin"] },
  { path: "/register-author", component: AuthorRegister, roles: ["admin"] },
  { path: "/blog-list", component: BlogList, roles: ["admin"] },
  { path: "/blog-details/:id", component: BlogDetails, roles: ["admin"] },
  { path: "/create-blog", component: CreateBlog, roles: ["admin"] },
  // FacilitatorMain
  { path: "/facilitator", component: FacilitatorSlotList, roles: ["teacher"] },

  { path: "/universityList", component: UniversityList, roles: ["admin"] },
  {
    path: "/facilitator/:teacherId",
    component: FacilitatorMain,
    roles: ["admin"],
  },

  {
    path: "/facilitator/:teacherId/create-time-slot",
    component: FacilitatorTimeSlot,
    roles: ["admin"],
  },
  {
    path: "/facilitator/:teacherId/view-class/:date",
    component: SingleFacilitatorSlot,
    roles: ["admin"],
  },
  {
    path: "/facilitator-exam/view-class/:date",
    component: FacilatorSingleDay,
    roles: ["teacher"],
  },
  {
    path: "/facilitator/view-class/:date/single-slot/:slotId/:packageId/:studentId",
    component: FacilitatorReview,
    roles: ["teacher"],
  },
  {
    path: "/facilitator-slot/:packageId/:studentId",
    component: FacilitatorPackage,
    roles: ["teacher"],
  },
  {
    path: "/facilitator-slot/:packageId/:studentId",
    component: FacilitatorPackage,
    roles: ["teacher"],
  },
  {
    path: "/facilitator/set-under-package/:packageId/:studentId",
    component: FacilitatorSinglePackage,
    roles: ["teacher"],
  },
  {
    path: "/facilitator/set/:packageId/:studentId",
    component: FacilitatorSinglePackage,
    roles: ["teacher"],
  },

  {
    path: "/facilitator/:packageId/:studentId/reading",
    component: FacilitatorReadingService,
    roles: ["teacher"],
  },
  {
    path: "/facilitator/:packageId/:studentId/listening",
    component: FacilitatorListeningService,
    roles: ["teacher"],
  },
  {
    path: "/facilitator/:packageId/:studentId/writing",
    component: FacilitatorWritingService,
    roles: ["teacher"],
  },
  {
    path: "/facilitator/:packageId/result/:studentId/:type/:setId",
    component: FacilitatorResult,
    roles: ["teacher"],
  },

  ///. speaking
  { path: "/speaking/:teacherId", component: SpeakingMain, roles: ["admin"] },
  {
    path: "/speaking/:teacherId/create-time-slot",
    component: SpeakingTimeSlotCreate,
    roles: ["admin"],
  },
  {
    path: "/speaking/:teacherId/view-class/:date",
    component: SingleDateSchedule,
    roles: ["admin"],
  },

  // student
  { path: "/students-list", component: StudentList, roles: ["admin"] },
  { path: "/user-list", component: UserList, roles: ["admin"] },
  {
    path: "/purchase/:studentId",
    component: PurchaseHistory,
    roles: ["admin"],
  },
  { path: "/student/:id", component: ViewProfile, roles: ["admin"] },

  // Services
  { path: "/services", component: Services, roles: ["admin"] },
  { path: "/create-service", component: CreateService, roles: ["admin"] },
  {
    path: "/edit-service/:id",
    component: () => <CreateService edit />,
    roles: ["admin"],
  },

  // course
  { path: "/courses", component: Course, roles: ["admin"] },
  { path: "/create-course", component: CreateCourse, roles: ["admin"] },
  {
    path: "/edit-course/:id",
    component: () => <CreateCourse edit />,
    roles: ["admin"],
  },
  { path: "/course-type", component: CourseType, roles: ["admin"] },
  { path: "/create-coursetype", component: CreateCourseType, roles: ["admin"] },
  {
    path: "/edit-coursetype/:id",
    component: () => <CreateCourseType edit />,
    roles: ["admin"],
  },

  // coupon
  { path: "/coupon", component: Coupon, roles: ["admin"] },
  { path: "/coupon/create-coupon", component: ManageCoupon, roles: ["admin"] },
  {
    path: "/coupon/edit-coupon/:id",
    component: ManageCoupon,
    roles: ["admin"],
  },

  // / review
  {
    path: "/review-answer",
    component: ReviewYourAnswer,
    roles: ["admin"],
  },

  // review
  {
    path: "/exam-data-review",
    component: ExamDataReview,
    roles: ["admin"],
  },
  {
    path: "/banner",
    component: BannerArea,
    roles: ["admin"],
  },
  // / review
  {
    path: "/review-answer/writing",
    component: ReviewAnswer,
    roles: ["admin"],
  },

  {
    path: "/review-answer/writing/:setId/:studentId",
    component: ReviewAnswerList,
    roles: ["admin"],
  },

  // reading

  // /set
  { path: "/reading-set/:id", component: ListSet, roles: ["admin"] },
  {
    path: "/service/:serviceId/create-reading-set",
    component: CreateSet,
    roles: ["admin"],
  },

  // .service
  {
    path: "/service/:serviceId/edit-reading-set/:setId",
    component: () => <CreateSet edit />,
    roles: ["admin"],
  },
  {
    path: "/service/:serviceId/reading/:setId",
    component: ListPassage,
    roles: ["admin"],
  },
  {
    path: "/service/:serviceId/reading/:setId/create-passage",
    component: CreatePassage,
    roles: ["admin"],
  },
  {
    path: "/service/:serviceId/reading/:setId/edit-passage/:id",
    component: () => <CreatePassage edit />,
    roles: ["admin"],
  },
  {
    path: "/service/:serviceId/reading/:setId/view-passage/:passageId",
    component: ViewPassage,
    roles: ["admin"],
  },

  // reading question
  {
    path: "/reading-exam/:passageId/mcq",
    component: MCQ,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/mcq/:questionId/:id",
    component: () => <MCQ edit />,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/multipleSelection",
    component: Multiple,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/multipleSelection/:questionId/:id",
    component: () => <Multiple edit />,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/trueFalse",
    component: TrueFalse,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/trueFalse/:questionId/:id",
    component: () => <TrueFalse edit />,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/YesNoQues",
    component: YesNoQuestion,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/YesNoQues/:questionId/:id",
    component: () => <YesNoQuestion edit />,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/comprehension",
    component: Comparison,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/comprehension/:questionId/:id",
    component: () => <Comparison edit />,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/headingMatching",
    component: HeadMatching,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/headingMatching/:questionId/:id",
    component: () => <HeadMatching edit />,
    roles: ["admin"],
  },
  // {
  //   path: "/reading-exam/:passageId/fillGapsWords",
  //   component: FillInTheBlanks,
  //   roles: ["admin"],
  // },
  // {
  //   path: "/reading-exam/:passageId/fillGapsWords/:questionId/:id",
  //   component: () => <FillInTheBlanks edit />,
  //   roles: ["admin"],
  // },
  {
    path: "/reading-exam/:passageId/fillGaps",
    component: FillInTheBlanks,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/fillGaps/:questionId/:id",
    component: () => <FillInTheBlanks edit />,
    roles: ["admin"],
  },

  {
    path: "/reading-exam/:passageId/fillGapsOne",
    component: FillInTheBlanksOne,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/fillGapsOne/:questionId/:id",
    component: () => <FillInTheBlanksOne edit />,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/fillGapsTwo",
    component: FillInTheBlanksTwo,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/fillGapsTwo/:questionId/:id",
    component: () => <FillInTheBlanksTwo edit />,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/fillGapsThree",
    component: FillInTheBlanksThree,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/fillGapsThree/:questionId/:id",
    component: () => <FillInTheBlanksThree edit />,
    roles: ["admin"],
  },

  {
    path: "/reading-exam/:passageId/fillGapsWords",
    component: FillBlanks,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/fillGapsWords/:questionId/:id",
    component: () => <FillBlanks edit />,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/preview/:type",
    component: PreviewQuestion,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/:type",
    component: Questions2,
    roles: ["admin"],
  },
  {
    path: "/reading-exam/:passageId/:type/:questionId/:id",
    component: () => <Questions2 edit />,
    roles: ["admin"],
  },

  /// listening
  // .set
  // /set
  { path: "/listening-set/:id", component: Listening, roles: ["admin"] },
  {
    path: "/service/:serviceId/create-listening-set",
    component: CreateListeningSet,
    roles: ["admin"],
  },
  {
    path: "/service/:serviceId/edit-listening-set/:setId",
    component: () => <CreateListeningSet edit />,
    roles: ["admin"],
  },
  {
    path: "/service/:serviceId/listening/:setId",
    component: SingleListeningSet,
    roles: ["admin"],
  },
  {
    path: "/service/:serviceId/listening/:setId/upload-audio",
    component: UploadAudio,
    roles: ["admin"],
  },
  {
    path: "/service/:serviceId/listening/:setId/update-audio/:id",
    component: () => <UploadAudio edit />,
    roles: ["admin"],
  },
  {
    path: "/service/:serviceId/listening/:setId/view-slice/:sliceId",
    component: ViewQuestions,
    roles: ["admin"],
  },

  // listening question
  {
    path: "/listening-exam/:passageId/mcq",
    component: ListeningMCQ,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/mcq/:questionId/:id",
    component: () => <ListeningMCQ edit />,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/multipleSelection",
    component: ListeningMultiple,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/multipleSelection/:questionId/:id",
    component: () => <ListeningMultiple edit />,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/trueFalse",
    component: ListeningTrueFalse,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/trueFalse/:questionId/:id",
    component: () => <ListeningTrueFalse edit />,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/YesNoQues",
    component: ListeningYesNoQuestion,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/YesNoQues/:questionId/:id",
    component: () => <ListeningYesNoQuestion edit />,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/comprehension",
    component: ListeningComparison,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/comprehension/:questionId/:id",
    component: () => <ListeningComparison edit />,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/headingMatching",
    component: ListeningHeadMatching,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/headingMatching/:questionId/:id",
    component: () => <ListeningHeadMatching edit />,
    roles: ["admin"],
  },
  // {
  //   path: "/listening-exam/:passageId/fillGapsWords",
  //   component: FillInTheBlanks,
  //   roles: ["admin"],
  // },
  // {
  //   path: "/listening-exam/:passageId/fillGapsWords/:questionId/:id",
  //   component: () => <FillInTheBlanks edit />,
  //   roles: ["admin"],
  // },
  {
    path: "/listening-exam/:passageId/fillGaps",
    component: ListeningFillInTheBlanks,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/fillGaps/:questionId/:id",
    component: () => <ListeningFillInTheBlanks edit />,
    roles: ["admin"],
  },

  {
    path: "/listening-exam/:passageId/fillGapsOne",
    component: ListeningFillInTheBlanksOne,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/fillGapsOne/:questionId/:id",
    component: () => <ListeningFillInTheBlanksOne edit />,
    roles: ["admin"],
  },

  {
    path: "/listening-exam/:passageId/fillGapsTwo",
    component: ListeningFillInTheBlanksTwo,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/fillGapsTwo/:questionId/:id",
    component: () => <ListeningFillInTheBlanksTwo edit />,
    roles: ["admin"],
  },

  {
    path: "/listening-exam/:passageId/fillGapsThree",
    component: ListeningFillInTheBlanksThree,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/fillGapsThree/:questionId/:id",
    component: () => <ListeningFillInTheBlanksThree edit />,
    roles: ["admin"],
  },

  {
    path: "/listening-exam/:passageId/fillGapsWords",
    component: ListeningFillBlanks,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/fillGapsWords/:questionId/:id",
    component: () => <ListeningFillBlanks edit />,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/preview/:type",
    component: ListeningPreviewQuestion,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/:type",
    component: Questions,
    roles: ["admin"],
  },
  {
    path: "/listening-exam/:passageId/:type/:questionId/:id",
    component: () => <Questions edit />,
    roles: ["admin"],
  },

  /// writing

  // /set
  { path: "/writing-set/:id", component: Writing, roles: ["admin"] },
  {
    path: "/service/:serviceId/create-writing-set",
    component: CreateWritingSet,
    roles: ["admin"],
  },

  // .service
  {
    path: "/service/:serviceId/edit-writing-set/:setId",
    component: () => <CreateWritingSet edit />,
    roles: ["admin"],
  },
  {
    path: "/service/:serviceId/writing/:setId",
    component: SingleWritingSet,
    roles: ["admin"],
  },
  {
    path: "/service/:serviceId/writing/:setId/create-question",
    component: CreateAcademicQues,
    roles: ["admin"],
  },
  {
    path: "/service/:serviceId/writing/:setId/edit-question/:questionId",
    component: () => <CreateAcademicQues edit />,
    roles: ["admin"],
  },
  {
    path: "/settings",
    component: () => <Settings edit />,
    roles: ["admin"],
  },

  //.... teacher
  {
    path: "/teacher-exam",
    component: TeacherExam,
    roles: ["teacher"],
  },
  {
    path: "/teacher-exam/view-class/:date",
    component: TeacherSingleDay,
    roles: ["teacher"],
  },
  {
    path: "/teacher-exam/view-class/:date/single-slot/:slotId",
    component: TeacherExamReview,
    roles: ["teacher"],
  },
  {
    path: "/teacher-exam/view-class/:date/single-slot/:slotId/mark",
    component: TeacherExamReviewResult,
    roles: ["teacher"],
  },

  {
    path: "/teacher-exam/result/:slotId",
    component: Result,
    roles: ["teacher", "admin"],
  },

  // {
  //   path: "/complate-question-table",
  //   component: ComplateQuesTable,
  //   roles: ["teacher", "admin"],
  // },

  // /profile

  {
    path: "/profile",
    component: Profile,
    roles: ["admin", "teacher", "student"],
  },
  {
    path: "/order-list",
    component: OrderList,
    roles: ["admin"],
  },
  {
    path: "/faqType",
    component: typeListFaq,
    roles: ["admin"],
  },
  {
    path: "/createFaqType",
    component: CreateTypeFaq,
    roles: ["admin"],
  },
  {
    path: "/updateFaqType/:id",
    component: EditTypeFaq,
    roles: ["admin"],
  },
  {
    path: "/editFaq/:id",
    component: EditFaq,
    roles: ["admin"],
  },
  {
    path: "/faqList",
    component: ListFAQ,
    roles: ["admin"],
  },
  {
    path: "/createFaqList",
    component: CreateListFAQ,
    roles: ["admin"],
  },
  {
    path: "/chat-box",
    component: ChatBox,
    roles: ["admin"],
  },
  {
    path: "/token-list",
    component: TokenList,
    roles: ["admin"],
  },
  {
    path: "/screen",
    component: SplashArea,
    roles: ["admin"],
  },
]

export { openRoute, authRoute }
