import axios from "axios"

//apply base url for axios
const REACT_APP_API =
  process.env.REACT_APP_API || "https://api-ieltsx.salesx-staging.xyz/api/v1"
  export const imageBaseUrl =
  "https://staging-ieltsx.sgp1.digitaloceanspaces.com/";

console.log("process here fouond", process.env, process.env?.REACT_APP_API)
export const writingImageUrl =
  "https://staging-ieltsx.sgp1.digitaloceanspaces.com/"

export const imageUrl =
  process.env.REACT_APP_IMAGE_ROOTURL ||
  "https://staging-ieltsx.sgp1.digitaloceanspaces.com/"

//main
const axiosApi = axios.create({
  baseURL: REACT_APP_API,
})
// const axiosApi = axios.create({
//   baseURL: "http://localhost:5000/api/v1",
// })

// axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => {
    console.log("ERR>>", error.response)
    return Promise.reject(error)
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, data, { ...config }).then(response => response.data)
}
export async function patch(url, data, config) {
  return axiosApi
    .patch(url, data, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
export async function image(url, data, authtoken) {
  return axiosApi
    .post(url, data, { headers: { Authorization: `Bearer ${authtoken}` } })
    .then((response) => response.data);
}

//export postLogin

export const postLogin = async (email, password) =>
  await axios.post(`${REACT_APP_API}/login`, {
    email,
    password,
  })
//export GoogleLogin

export const axiosGoogleLogin = async (email, fullName) =>
  await axios.post(`https://api-ieltsx.salesx-staging.xyz/api/google-login`, {
    email,
    fullName,
  })

//export postRegistration
export const axiosRegistration = async (
  fullName,
  email,
  phoneNumber,
  password,
  confirmPassword
) =>
  await axios.post(`${REACT_APP_API}/signup`, {
    fullName,
    email,
    phoneNumber,
    password,
    confirmPassword,
  })

//send email and verificationcode
export const axiosVerifyEmail = async (email, accountActivateToken) =>
  await axios.patch(`${REACT_APP_API}/verifyaccount`, {
    email,
    accountActivateToken,
  })

//Forget Password
export const axiosForgetPassword = async email =>
  await axios.patch(`${REACT_APP_API}/forgot-password`, {
    email,
  })

//update Password
export const axiosUpdatePassword = async (
  email,
  accountActivateToken,
  password,
  confirmPassword
) =>
  await axios.patch(`${REACT_APP_API}/update-password`, {
    email,
    accountActivateToken,
    password,
    confirmPassword,
  })

//update profile password
export const axiosUpdateProfilePassword = async (
  authtoken,
  currentPassword,
  password,
  confirmPassword
) =>
  await axios.patch(
    `${REACT_APP_API}/user/profile/update-pass`,
    {
      authtoken,
      currentPassword,
      password,
      confirmPassword,
    },
    {
      headers: { authtoken },
    }
  )

export const axiosCreatePackage = async (
  packageName,
  packageLength,
  packagePrice,
  packageDescription,
  expireDate,
  authtoken
) =>
  await axios.post(
    `${REACT_APP_API}/package/create-package`,
    {
      packageName,
      packageLength,
      packagePrice,
      packageDescription,
      expireDate,
    },
    { headers: { authtoken } }
  )

//get packages
export const axiosGetPackages = async authtoken =>
  await axios.get(`${REACT_APP_API}/package/get-all-packages`, {
    headers: { authtoken },
  })

//get single Package
export const axiosGetSinglePackage = async (authtoken, packageId) =>
  await axios.get(`${REACT_APP_API}/package/get-single-package/${packageId}`, {
    headers: { authtoken },
  })

//add service to package
export const axiosAddServiceToPackage = async (
  authtoken,
  serviceId,
  packageId
) =>
  // console.log("line from 162", authtoken, serviceId, packageId)
  await axios.patch(
    `${REACT_APP_API}/package/add-service-to-package/:${packageId}`,
    {
      serviceId,
    },
    {
      headers: { authtoken },
    }
  )
// /api/package/service/reading/:setId/create-reading-set
// /api/package/add-service-to-package/:packageId

//teacherregister
export const axiosTeacherRegister = async (
  fullName,
  email,
  phoneNumber,
  authtoken
) =>
  await axios.post(
    `${REACT_APP_API}/teacher/account-register`,
    {
      fullName,
      email,
      phoneNumber,
    },
    { headers: { authtoken } }
  )

//get packages
export const axiosGetTeachers = async authtoken =>
  await axios.get(`${REACT_APP_API}/admin/get-teachers`, {
    headers: { authtoken },
  })

//for services
//create service
export const axiosAddService = async (authtoken, serviceName) =>
  await axios.post(
    `${REACT_APP_API}/package/service/create-service`,
    { serviceName },
    {
      headers: { authtoken },
    }
  )
export const axiosGetServices = async authtoken =>
  await axios.get(
    `${REACT_APP_API}/package/service/get-all-services`,

    {
      headers: { authtoken },
    }
  )

//get students
export const axiosGetStudents = async authtoken =>
  await axios.get(`${REACT_APP_API}/admin/get-students`, {
    headers: { authtoken },
  })

//get sinlge student
export const axiosGetSingleStudent = async (authtoken, studentId) =>
  await axios.get(`${REACT_APP_API}/admin/get-student/${studentId}`, {
    headers: { authtoken },
  })

//creteReadingSet /api/package/service/reading/:setId/create-reading-set
export const axiosCreateReadingSet = async (
  setId,
  setName,
  totalQuestion,
  authtoken
) =>
  await axios.post(
    `${REACT_APP_API}/package/service/reading/${setId}/create-reading-set`,
    {
      totalQuestion,
      setName,
    },
    { headers: { authtoken } }
  )

export const axiosGetReadingSet = async (setId, authtoken) =>
  await axios.get(`${REACT_APP_API}/package/service/reading/${setId}`, {
    headers: { authtoken },
  })
//creteWrittingSet /api/package/service/writting/:setId/create-writting-set
export const axiosCreateWrittingSet = async (
  setId,
  setName,
  totalQuestion,
  authtoken
) =>
  await axios.post(
    `${REACT_APP_API}/package/service/writting/${setId}/create-writting-set`,
    {
      totalQuestion,
      setName,
    },
    { headers: { authtoken } }
  )
// /api/package/service/writting/:setId
export const axiosGetWrittingSets = async (setId, authtoken) =>
  await axios.get(`${REACT_APP_API}/package/service/writting/${setId}`, {
    headers: { authtoken },
  })

export const axiosCreateReadingPassage = async (setId, data, authtoken) =>
  await axios.post(
    `${REACT_APP_API}/package/reading/readingservice/${setId}/passagecreate`,
    data,
    { headers: { authtoken } }
  )

export const axiosgetReadingPassages = async authtoken =>
  await axios.post(
    `${REACT_APP_API}/package/reading/readingservice/allpassage`,
    { headers: { authtoken } }
  )

export const axiosReadingSinglePassage = async (
  setId,
  passageId,
  data,
  authtoken
) =>
  await axios.post(
    `${REACT_APP_API}/package/reading/readingservice/${setId}/${passageId}`,
    { headers: { authtoken } }
  )

//creteListeningSet /api/package/service/listening/:setId/create-listening-set
export const axiosCreateListeningSet = async (
  setId,
  setName,
  totalQuestion,
  authtoken
) =>
  await axios.post(
    `${REACT_APP_API}/package/service/listening/${setId}/create-listening-set`,
    {
      totalQuestion,
      setName,
    },
    { headers: { authtoken } }
  )

export const axiosGetListeningSets = async (setId, authtoken) =>
  await axios.get(`${REACT_APP_API}/package/service/listening/${setId}`, {
    headers: { authtoken },
  })
//creteReadingDescription /api/package/service/reading/readingset:readingSet/create-reading-description
export const axiosCreateReadingDescription = async (
  readingSet,
  readingPessageTopicName,
  readingPessageDescription,
  totalQuestion,
  authtoken
) =>
  await axios.post(
    `${REACT_APP_API}/package/reading/${readingSet}/passagecreate`,
    {
      readingPessageTopicName,
      readingPessageDescription,
      totalQuestion,
    },
    { headers: { authtoken } }
  )

export const axiosGetReadingDescriptions = async (readingSet, authtoken) =>
  await axios.get(`${REACT_APP_API}/package/reading/allpassage`, {
    headers: { authtoken },
  })
