import CardComponent from "components/Common/CardComponent"
import CustomInput from "components/Common/CustomInput"
import Loader from "components/Common/Loader"
import CheckBox from "components/InputComponent/CheckBox"
import SelectField from "components/InputComponent/CustomSelect"
import TextError from "components/InputComponent/TextError"
import { createPackageSchema } from "components/Schemas/createPackageSchema"
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik"
import { imageBaseUrl, imageUrl } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import InnerLayer from "Layout/InnerLayer"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import { Button, Card, Col, Row } from "reactstrap"
import {
  addPackage,
  getService,
  getSinglePackage,
  getSinglePackageSuccess,
} from "store/actions"
import { getCourseType } from "store/CourseType/actions"
import Upload from "./Upload"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Package List",
    link: "/packages",
  },
  {
    title: "Create Package",
    link: "#",
  },
]

const breadcrumbItems2 = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Package List",
    link: "/packages",
  },
  {
    title: "Edit Package",
    link: "#",
  },
]

const initialValues = {
  packageName: "",
  packageService: [""],
  tags: [""],
  aboutPackage: [{ question: "", answer: "" }],
  features: [""],
  advantage: [""],
  level: "",
  content: "",
  courseType: "",
  eligible: "",
  discount: "",
  price: "",
  validity: "",
  expire: "",
  description: "",
  priceType: "",
  readingCheck: false,
  writingCheck: false,
  speakingCheck: false,
  listeningCheck: false,
  includedFacilitator: false,
  totalFacilitator: "",

}

const CreatePackage = ({ history, edit }) => {
  console.log(
    "🚀 ~ file: CreatePackage.js ~ line 75 ~ CreatePackage ~ edit",
    edit
  )
  const [listValue, setListValue] = useState(null)
  const [fileError, setFileError] = useState("")
  const dispatch = useDispatch()
  const { id } = useParams()
  const [tags, setTags] = useState([""])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedService, setselectedService] = useState(null)
  const [selected, setSelected] = useState("no")
  const [isChecked, setIsChecked] = useState(false)
  const {
    services,
    authToken,
    loading,
    data,
    typeLoading,
    adding,
    singlePackage,
    singleLoading,
  } = useSelector(state => ({
    services: state.serviceReducer.services,
    authToken: state.Login.token,
    loading: state.serviceReducer.loading,
    data: state.courseType?.data,
    typeLoading: state.courseType.loading,
    singlePackage: state.packageReducer.singlePackage?.singlePackage,
    packages: state.packageReducer,
    adding: state.packageReducer.loading,
    singleLoading: state.packageReducer.singleLoading,
  }))
console.log('singlePackage', singlePackage);
  useEffect(() => {
    if (edit && singleLoading === false) {
      const data = { ...listValue }
      data.packageName = singlePackage?.packageName
      data.packageService = singlePackage?.packageService?.map(
        data => data._id
      ) || [""]
      data.description = singlePackage?.description
      data.tags = singlePackage?.tags?.length > 0 ? singlePackage?.tags : [""]
      data.advantage =
        singlePackage?.advantage?.length > 0 ? singlePackage?.advantage : [""]
      data.features =
        singlePackage?.features?.length > 0 ? singlePackage?.features : [""]
      data.aboutPackage =
        singlePackage?.aboutPackage?.length > 0
          ? singlePackage?.aboutPackage
          : [{ question: "", answer: "" }]
      data.expire = singlePackage?.expire
      data.level = singlePackage?.level
      data.validity = singlePackage?.validity
      data.content = singlePackage?.content
      data.discount = singlePackage?.discount
      data.eligible = singlePackage?.eligible
      data.price = singlePackage?.price || ""
      data.priceType = singlePackage?.price ? "paid" : "free"
      data.courseType = singlePackage?.courseType?._id
      data.includedFacilitator = singlePackage?.includedFacilitator || false
      data.readingCheck = singlePackage?.readingCheck || false
      data.listeningCheck = singlePackage?.listeningCheck || false
      data.speakingCheck = singlePackage?.speakingCheck || false
      data.writingCheck = singlePackage?.writingCheck || false
      setListValue(data)
    }
  }, [singlePackage])
  // console.log(`CreatePackage ~ singlePackage`, singlePackage)
  useEffect(() => {
    dispatch(getCourseType(authToken))
  }, [])
  //   console.log(`Course ~ data`, data)
  useEffect(() => {
    dispatch(getSinglePackageSuccess({}))
    dispatch(getService(authToken))
    if (edit) {
      dispatch(getSinglePackage(id, authToken))
    }
    return () => {
      dispatch(getSinglePackageSuccess({}))
    }
  }, [])

  useEffect(() => {
    setTags(singlePackage?.tags || [""])
    setselectedService(singlePackage?.packageService || [])
  }, [singlePackage])

  // let serviceName
  // if (!loading) {
  //   ;(services.getDatas || []).map((data, key) => {
  //     serviceName = data.serviceName
  //   })
  // }
  const check = data => {
    return data.every(dt => dt !== "")
  }

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setFileError("")
    }
  }, [selectedFiles])

  // const handleMultiChange = e => {
  //   setselectedService(Array.isArray(e) ? e.map(x => x._id) : [])
  // }
  const onSubmit = values => {
    // console.log(values)
    if (selectedFiles.length === 0 && !edit) {
      return setFileError("Please select a image")
    }
    const { tags, packageService, aboutPackage, features, advantage,listeningCheck,speakingCheck,writingCheck,readingCheck, ...rest } =
      values
    const { courseType, ...next } = rest
    const fd = new FormData()
    for (let obj in edit ? next : rest) {
      fd.append(obj, values[obj])
    }
    if (selectedFiles.length > 0) {
      fd.append("photo", selectedFiles?.[0])
    }
 
    !edit &&
      packageService.forEach((data, idx) => {
        fd.append(`packageService[${idx}]`, data)
      })
    tags.forEach((data, idx) => {
      fd.append(`tags[${idx}]`, data)
    })
    features.forEach((data, idx) => {
      fd.append(`features[${idx}]`, data)
    })
    advantage.forEach((data, idx) => {
      fd.append(`advantage[${idx}]`, data)
    })
    aboutPackage.forEach((data, idx) => {
      fd.append(`aboutPackage[${idx}][question]`, data?.question)
      fd.append(`aboutPackage[${idx}][answer]`, data?.answer)
    })
    for (const obj of fd.entries()) {
      console.log(obj)
    }
    console.log("fd", fd)
    if (edit) {
      dispatch(addPackage(fd, history, authToken, id))
    } else {
      dispatch(addPackage(fd, history, authToken))
    }
  }

  const handleChange = event => {
    console.log(event.target.value)
    setSelected(event.target.value)
  }
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return '';
  };
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={edit ? "Edit Package" : "Create Package"}
        breadcrumbItems={edit ? breadcrumbItems2 : breadcrumbItems}
      >
        <Row className="mt-3">
          <CardComponent>
            <h5>{edit ? "Edit" : "Create"} Package</h5>
            {singleLoading && edit ? (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            ) : (
              <Formik
                initialValues={listValue || initialValues}
                validationSchema={createPackageSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({ values, errors, setFieldValue }) => (
                  <Form className="mt-2">
                    {console.log("values.", values)}
                    <Row className="mb-3">
                      <Col md="3">Package Name</Col>
                      <Col md="9">
                        <Field
                          name="packageName"
                          type="text"
                          component={CustomInput}
                          placeholder="Type package name"
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md="3">Price Type</Col>
                      <Col md="9">
                        <Field
                          name="priceType"
                          type="select"
                          component={CustomInput}
                          placeholder="Select course type"
                          // options={typeLoading ? data : []}
                          defaultText="Course Type"
                          disabled={edit}
                        >
                          <option value="">Select Course Type</option>
                          <option value="free">Free</option>
                          <option value="paid">Paid</option>
                        </Field>
                      </Col>
                    </Row>
                    {values.priceType === "paid" ? (
                      <Row className="mb-3">
                        <Col md="3">Package Price</Col>
                        <Col md="9">
                          <Field
                            name="price"
                            type="number"
                            component={CustomInput}
                            placeholder="Type package price"
                          />
                        </Col>
                      </Row>
                    ) : null}
                    <Row className="mb-3">
                      <Col md="3">Package Validity</Col>
                      <Col md="9">
                        <Field
                          name="validity"
                          type="number"
                          component={CustomInput}
                          placeholder="Type package validity"
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md="3">Package Discount</Col>
                      <Col md="9">
                        <Field
                          name="discount"
                          type="number"
                          component={CustomInput}
                          placeholder="Type package discount"
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md="3">Eligible</Col>
                      <Col md="9">
                        <Field
                          name="eligible"
                          type="text"
                          component={CustomInput}
                          placeholder="Type eligible"
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md="3">Course Type</Col>
                      <Col md="9">
                        {typeLoading ? (
                          <Loader />
                        ) : (
                          <Field
                            name="courseType"
                            type="select"
                            component={CustomInput}
                            placeholder="Select course type"
                            // options={typeLoading ? data : []}
                            defaultText="Course Type"
                            disabled={edit}
                          >
                            <option value="">Select Course Type</option>
                            {data?.length > 0
                              ? data?.map((option, idx) => (
                                  <option key={idx} value={option?._id}>
                                    {option?.courseTypeName}
                                  </option>
                                ))
                              : null}
                          </Field>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3">Package Service</Col>
                      <Col md="9">
                        {loading ? (
                          <div className="">
                            <Loader />
                          </div>
                        ) : (
                          <React.Fragment>
                            <Field
                              name="packageService"
                              id="packageService"
                              component={SelectField}
                              placeholder="Select Service"
                              options={services.getDatas || []}
                              value={"_id"}
                              nameData={"serviceName"}
                              isMulti={true}
                              isDisabled={edit}
                            />
                            <ErrorMessage
                              name={`packageService`}
                              component={TextError}
                            />
                          </React.Fragment>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3">Reading</Col>
                      <Col md="3">
                        <Field
                          name="readingCheck"
                          type="checkbox"
                          label="Available"
                          checked={values.readingCheck === true}
                          component={CheckBox}
                          placeholder="Quantity"
                          id="readingCheck"
                          disabled={edit}
                        ></Field>
                      </Col>
                     {values.readingCheck === true && <Col md="6">
                        <Field
                          name="readingQ"
                          type="select"
                          component={CustomInput}
                          placeholder="Quantity"
                          // options={typeLoading ? data : []}
                          defaultText="Quantity"
                          disabled={edit}
                        >
                          <option value="">Select Quantity</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </Field>
                      </Col>}
                    </Row>
                    <Row className="mb-3">
                      <Col md="3">Writing</Col>
                      <Col md="3">
                        <Field
                          name="writingCheck"
                          type="checkbox"
                          label="Available"
                          checked={values.writingCheck === true}
                          component={CheckBox}
                          placeholder="Quantity"
                          id="writingCheck"
                          disabled={edit}
                        ></Field>
                      </Col>
                     {values.writingCheck === true && <Col md="6">
                        <Field
                          name="writingQ"
                          type="select"
                          component={CustomInput}
                          placeholder="Quantity"
                          // options={typeLoading ? data : []}
                          defaultText="Quantity"
                          disabled={edit}
                        >
                          <option value="">Select Quantity</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </Field>
                      </Col>}
                    </Row>
                    <Row className="mb-3">
                      <Col md="3">Listening</Col>
                      <Col md="3">
                        <Field
                          name="listeningCheck"
                          type="checkbox"
                          label="Available"
                          checked={values.listeningCheck === true}
                          component={CheckBox}
                          placeholder="Quantity"
                          id="listeningCheck"
                          disabled={edit}
                        ></Field>
                      </Col>
                     {values.listeningCheck === true && <Col md="6">
                        <Field
                          name="listeningQ"
                          type="select"
                          component={CustomInput}
                          placeholder="Quantity"
                          // options={typeLoading ? data : []}
                          defaultText="Quantity"
                          disabled={edit}
                        >
                          <option value="">Select Quantity</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </Field>
                      </Col>}
                    </Row>
                    <Row className="mb-3">
                      <Col md="3">Speaking</Col>
                      <Col md="3">
                        <Field
                          name="speakingCheck"
                          type="checkbox"
                          label="Available"
                          checked={values.speakingCheck === true}
                          component={CheckBox}
                          placeholder="Quantity"
                          id="speakingCheck"
                          disabled={edit}
                        ></Field>
                      </Col>
                     {values.speakingCheck === true && <Col md="6">
                        <Field
                          name="speakingQ"
                          type="select"
                          component={CustomInput}
                          placeholder="Quantity"
                          // options={typeLoading ? data : []}
                          defaultText="Quantity"
                          disabled={edit}
                        >
                          <option value="">Select Quantity</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </Field>
                      </Col>}
                    </Row>
                    <Row className="mb-3">
                      <Col md="3">Facilitator</Col>
                      <Col md="3">
                        <Field
                          name="includedFacilitator"
                          type="checkbox"
                          label="Available"
                          checked={values.includedFacilitator === true}
                          component={CheckBox}
                          placeholder="Quantity"
                          id="includedFacilitator"
                          disabled={edit}
                        ></Field>
                      </Col>
                     {values.includedFacilitator === true && <Col md="6">
                        <Field
                          name="totalFacilitator"
                          type="select"
                          component={CustomInput}
                          placeholder="Quantity"
                          // options={typeLoading ? data : []}
                          defaultText="Quantity"
                          disabled={edit}
                        >
                          <option value="">Select Quantity</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </Field>
                      </Col>}
                    </Row>

               

                    {/*  */}
                    <Row className="mb-3">
                      <Col md="3">Package Expired</Col>
                      <Col md="9">
                        <Field
                          name="expire"
                          type="number"
                          component={CustomInput}
                          placeholder="Type expire"
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md="3">Package Content</Col>
                      <Col md="9">
                        <Field
                          name="content"
                          type="text"
                          component={CustomInput}
                          placeholder="Type project content..."
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md="3">Level</Col>
                      <Col md="9">
                        <Field
                          name="level"
                          type="text"
                          component={CustomInput}
                          placeholder="Type level"
                        />
                      </Col>
                    </Row>

                    {/*  */}
                    <Row className="mb-3">
                      <Col md="3">Package Description</Col>
                      <Col md="9">
                        <Field
                          name="description"
                          type="textarea"
                          rows="3"
                          component={CustomInput}
                          placeholder="Type project description..."
                        />
                      </Col>
                    </Row>

                    {/* tags */}
                    <Row className="mb-3">
                      <Col md="3">Popular Tags</Col>
                      <Col md="9">
                        <FieldArray
                          name="tags"
                          render={arrayHelpers => (
                            <div>
                              {values?.tags?.map((value, index) => (
                                <div
                                  key={index}
                                  className="mb-2 position-relative"
                                >
                                  <Field
                                    name={`tags.${index}`}
                                    type={"text"}
                                    component={CustomInput}
                                    placeholder="Type tags"
                                  />
                                  {index > 0 ? (
                                    <div
                                      className="position-absolute h-100"
                                      style={{
                                        top: "50%",
                                        right: "0",
                                        transform: "translateY(-45%)",
                                      }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <Button
                                        className="btn-sm removeShadow"
                                        style={{
                                          background: "transparent",
                                          border: "none",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <i
                                          className="bx bx-trash"
                                          style={{ color: "#999" }}
                                        ></i>
                                      </Button>
                                    </div>
                                  ) : null}
                                  <ErrorMessage
                                    name={`tags.${index}`}
                                    component={TextError}
                                  />
                                </div>
                              ))}
                              <Button
                                type="button"
                                color="primary"
                                style={{
                                  background: "transparent",
                                  color: "#485ec4",
                                  border: "1px solid #485ec4",
                                }}
                                onClick={() => arrayHelpers.push("")}
                              >
                                + Add more
                              </Button>
                            </div>
                          )}
                        />
                      </Col>
                    </Row>

                    {/* Features */}
                    <Row className="mb-3">
                      <Col md="3">Features</Col>
                      <Col md="9">
                        <FieldArray
                          name="features"
                          render={arrayHelpers => (
                            <div>
                              {values?.features?.map((value, index) => (
                                <div
                                  key={index}
                                  className="mb-2 position-relative"
                                >
                                  <Field
                                    name={`features.${index}`}
                                    type={"text"}
                                    component={CustomInput}
                                    placeholder="Type features"
                                  />
                                  {index > 0 ? (
                                    <div
                                      className="position-absolute h-100"
                                      style={{
                                        top: "50%",
                                        right: "0",
                                        transform: "translateY(-45%)",
                                      }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <Button
                                        className="btn-sm removeShadow"
                                        style={{
                                          background: "transparent",
                                          border: "none",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <i
                                          className="bx bx-trash"
                                          style={{ color: "#999" }}
                                        ></i>
                                      </Button>
                                    </div>
                                  ) : null}
                                  <ErrorMessage
                                    name={`features.${index}`}
                                    component={TextError}
                                  />
                                </div>
                              ))}
                              <Button
                                type="button"
                                color="primary"
                                style={{
                                  background: "transparent",
                                  color: "#485ec4",
                                  border: "1px solid #485ec4",
                                }}
                                onClick={() => arrayHelpers.push("")}
                              >
                                + Add more
                              </Button>
                            </div>
                          )}
                        />
                      </Col>
                    </Row>
                    {/* advantage */}
                    <Row className="mb-3">
                      <Col md="3">Advantage</Col>
                      <Col md="9">
                        <FieldArray
                          name="advantage"
                          render={arrayHelpers => (
                            <div>
                              {values?.advantage?.map((value, index) => (
                                <div
                                  key={index}
                                  className="mb-2 position-relative"
                                >
                                  <Field
                                    name={`advantage.${index}`}
                                    type={"text"}
                                    component={CustomInput}
                                    placeholder="Type advantage"
                                  />
                                  {index > 0 ? (
                                    <div
                                      className="position-absolute h-100"
                                      style={{
                                        top: "50%",
                                        right: "0",
                                        transform: "translateY(-45%)",
                                      }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <Button
                                        className="btn-sm removeShadow"
                                        style={{
                                          background: "transparent",
                                          border: "none",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <i
                                          className="bx bx-trash"
                                          style={{ color: "#999" }}
                                        ></i>
                                      </Button>
                                    </div>
                                  ) : null}
                                  <ErrorMessage
                                    name={`advantage.${index}`}
                                    component={TextError}
                                  />
                                </div>
                              ))}
                              <Button
                                type="button"
                                color="primary"
                                style={{
                                  background: "transparent",
                                  color: "#485ec4",
                                  border: "1px solid #485ec4",
                                }}
                                onClick={() => arrayHelpers.push("")}
                              >
                                + Add more
                              </Button>
                            </div>
                          )}
                        />
                      </Col>
                    </Row>

                    {/* AboutPackage */}
                    <Row className="mb-3">
                      <Col md="3">About Package</Col>
                      <Col md="9">
                        <FieldArray
                          name="aboutPackage"
                          render={arrayHelpers => (
                            <div>
                              {values?.aboutPackage?.map((value, index) => (
                                <Row
                                  key={index}
                                  className="mb-2 position-relative"
                                >
                                  <Col md="6">
                                    <Field
                                      name={`aboutPackage.${index}.question`}
                                      type={"text"}
                                      component={CustomInput}
                                      placeholder="Type question"
                                    />
                                    <ErrorMessage
                                      name={`aboutPackage.${index}.question`}
                                      component={TextError}
                                    />
                                  </Col>
                                  <Col md="6">
                                    <Field
                                      name={`aboutPackage.${index}.answer`}
                                      type={"text"}
                                      component={CustomInput}
                                      placeholder="Type answer"
                                    />
                                    {index > 0 ? (
                                      <div
                                        className="position-absolute h-100"
                                        style={{
                                          top: "50%",
                                          right: "0",
                                          transform: "translateY(-45%)",
                                        }}
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <Button
                                          className="btn-sm removeShadow"
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                            fontSize: "18px",
                                          }}
                                        >
                                          <i
                                            className="bx bx-trash"
                                            style={{ color: "#999" }}
                                          ></i>
                                        </Button>
                                      </div>
                                    ) : null}
                                    <ErrorMessage
                                      name={`aboutPackage.${index}.answer`}
                                      component={TextError}
                                    />
                                  </Col>
                                </Row>
                              ))}
                              <Button
                                type="button"
                                color="primary"
                                style={{
                                  background: "transparent",
                                  color: "#485ec4",
                                  border: "1px solid #485ec4",
                                }}
                                onClick={() => arrayHelpers.push("")}
                              >
                                + Add more
                              </Button>
                            </div>
                          )}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md="3">Cover Image</Col>
                      <Col md="9">
                        <Upload
                          uploadMessage={
                            selectedFiles.length > 0 || edit
                              ? "Drop files here or click to change."
                              : "Drop files here or click to upload."
                          }
                          setSelectedFiles={setSelectedFiles}
                        />
                        {selectedFiles?.length === 0 && fileError !== "" ? (
                          <TextError>{fileError}</TextError>
                        ) : null}
                        <Row>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {selectedFiles?.length ? (
                              selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })
                            ) : singlePackage?.photo ? (
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={"photo"}
                                      src={getPhotoUrl(singlePackage?.photo)}
                                      
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {singlePackage?.photo}
                                    </Link>
                                    {/* <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p> */}
                                  </Col>
                                </Row>
                              </div>
                            ) : null}
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="9">
                        <Button color="primary" disabled={adding} type="submit">
                          {adding
                            ? "Submitting..."
                            : edit
                            ? "Update Package"
                            : "Add Package"}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            )}
            {/* <AvForm className="mt-4" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md="3">Package Name</Col>
                <Col md="9">
                  <AvField
                    name="packageName"
                    type="text"
                    errorMessage="Type package name"
                    placeholder="Type package name"
                    value={singlePackage?.packageName}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="3">Package Price</Col>
                <Col md="9">
                  <AvField
                    name="price"
                    type="number"
                    errorMessage="Type package price"
                    placeholder="Type package price"
                    value={singlePackage?.price}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md="3">Package Validity</Col>
                <Col md="9">
                  <AvField
                    name="validity"
                    type="number"
                    errorMessage="Type package validity"
                    value={singlePackage?.validity}
                    placeholder="Type package validity"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="3">Package Discount</Col>
                <Col md="9">
                  <AvField
                    name="discount"
                    type="number"
                    value={singlePackage?.discount}
                    errorMessage="Type package discount"
                    placeholder="Type package discount"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="3">Eligible</Col>
                <Col md="9">
                  <AvField
                    name="eligible"
                    type="text"
                    value={singlePackage?.eligible}
                    errorMessage="Type eligible"
                    placeholder="Type eligible"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="3">Course Type</Col>
                <Col md="9">
                  {typeLoading ? (
                    <Loader />
                  ) : (
                    <AvField
                      type="select"
                      name="courseType"
                      disabled={edit}
                      errorMessage="Select course type"
                      placeholder="Select course type"
                      value={singlePackage?.courseType?._id}
                      required
                    >
                      <option value="">Select Course Type</option>
                      {data?.map((dt, idx) => (
                        <option key={idx} value={dt._id}>
                          {dt.courseTypeName}
                        </option>
                      ))}
                    </AvField>
                  )}
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="3">Package Service</Col>
                <Col md="9">
                  {loading ? (
                    <div className="">
                      <Loader />
                    </div>
                  ) : (
                    <Select
                      name="packageService"
                      cacheOptions
                      getOptionLabel={e => toCapitalize(e.serviceName)}
                      getOptionValue={e => e._id}
                      options={services.getDatas}
                      isDisabled={edit}
                      value={singlePackage?.packageService}
                      isClearable
                      onChange={handleMultiChange}
                      isMulti
                    />
                  )}
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="3">Package Expired</Col>
                <Col md="9">
                  <AvField
                    name="expire"
                    required
                    value={singlePackage?.expire}
                    placeholder="Type expire"
                    type="number"
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="3">Package Content</Col>
                <Col md="9">
                  <AvField
                    name="content"
                    type="text"
                    value={singlePackage?.content}
                    errorMessage="Type content"
                    placeholder="Type project content..."
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="3">Package Description</Col>
                <Col md="9">
                  <AvField
                    name="description"
                    type="textarea"
                    value={singlePackage?.description}
                    rows="3"
                    errorMessage="Type description"
                    placeholder="Type project description..."
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="3">Popular Tag</Col>
                <Col md="9">
                  {tags.map((data, idx) => (
                    <Input
                      type="text"
                      className="mb-2"
                      onChange={e => {
                        const data = [...tags]
                        data[idx] = e.target.value
                        setTags(data)
                      }}
                      placeholder={`Type tag ${idx + 1}`}
                      key={idx}
                      required
                      value={data}
                    />
                  ))}
                  <Button
                    className="btn-sm btn-success"
                    onClick={() => setTags(prev => [...prev, ""])}
                  >
                    Add More
                  </Button>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="3">Cover Image</Col>
                <Col md="9">
                  <Upload
                    uploadMessage={""}
                    setSelectedFiles={setSelectedFiles}
                  />
                  <Row>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles?.length ? (
                        selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )
                        })
                      ) : singlePackage?.photo ? (
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={"photo"}
                                src={`${image}${singlePackage?.photo}`}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {singlePackage?.photo}
                              </Link>
                            </Col>
                          </Row>
                        </div>
                      ) : null}
                    </div>
                  </Row>
                </Col>
              </Row>

              <div>
                <button
                  disabled={adding}
                  type="submit"
                  className="btn btn-primary w-md"
                >
                  {adding
                    ? "Submitting..."
                    : edit
                    ? "Update Package"
                    : "Create Package"}
                </button>
              </div>
            </AvForm> */}
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(CreatePackage)
