import { CREATE_UNIVERSITY, CREATE_UNIVERSITY_FAIL, CREATE_UNIVERSITY_SUCCESS, GET_AVG_SCORE, GET_AVG_SCORE_FAIL, GET_AVG_SCORE_SUCCESS, GET_ENROLLMENT_STATUS, GET_ENROLLMENT_STATUS_FAIL, GET_ENROLLMENT_STATUS_SUCCESS, GET_NET_AMOUNT, GET_NET_AMOUNT_FAIL, GET_NET_AMOUNT_SUCCESS, GET_NUMBER_STUDENTS, GET_NUMBER_STUDENTS_FAIL, GET_NUMBER_STUDENTS_SUCCESS, GET_PACKAGE_CONTRIBUTION, GET_PACKAGE_CONTRIBUTION_FAIL, GET_PACKAGE_CONTRIBUTION_SUCCESS, GET_UNIVERSITY, GET_UNIVERSITY_FAIL, GET_UNIVERSITY_SUCCESS, STORE_ADMIN_DASHBOARD_DATA } from "./actionTypes";



  export const getStudentInfo = (token) => ({
    type: GET_NUMBER_STUDENTS,
    payload: { token },
  });
  
  export const getStudentInfoSuccess = (data) => ({
    type: GET_NUMBER_STUDENTS_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getStudentInfoFail = (data) => ({
    type: GET_NUMBER_STUDENTS_FAIL,
    payload: { data },
  });
  
  export const getDashboardAvgScore = (token, score) => ({
    type: GET_AVG_SCORE,
    payload: { token, score },
  });
  
  export const getDashboardAvgScoreSuccess = (data) => ({
    type: GET_AVG_SCORE_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getDashboardAvgScoreFail = (data) => ({
    type: GET_AVG_SCORE_FAIL,
    payload: { data },
  });
  export const getNetAmount = (token) => ({
    type: GET_NET_AMOUNT,
    payload: { token },
  });
  
  export const getNetAmountSuccess = (data) => ({
    type: GET_NET_AMOUNT_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getNetAmountFail = (data) => ({
    type: GET_NET_AMOUNT_FAIL,
    payload: { data },
  });


  export const getPackageContribution = (token, valueStatus) => ({
    type: GET_PACKAGE_CONTRIBUTION,
    payload: { token, valueStatus },
  });
  
  export const getPackageContributionSuccess = (data) => ({
    type: GET_PACKAGE_CONTRIBUTION_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getPackageContributionFail = (data) => ({
    type: GET_PACKAGE_CONTRIBUTION_FAIL,
    payload: { data },
  });
  
  export const getEnrollmentStatus = (token, timeline, singlePackage) => ({
    type: GET_ENROLLMENT_STATUS,
    payload: { token, timeline, singlePackage },
  });
  
  export const getEnrollmentStatusSuccess = (data) => ({
    type: GET_ENROLLMENT_STATUS_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getEnrollmentStatusFail = (data) => ({
    type: GET_ENROLLMENT_STATUS_FAIL,
    payload: { data },
  });


  export const getUniversityList = (token) => ({
    type: GET_UNIVERSITY,
    payload: { token },
  });
  
  export const getUniversityListSuccess = (data) => ({
    type: GET_UNIVERSITY_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getUniversityListFail = (data) => ({
    type: GET_UNIVERSITY_FAIL,
    payload: { data },
  });
  export const createUniversity = (data, token, history, setShow) => ({
    type: CREATE_UNIVERSITY,
    payload: { data, token, history, setShow },
  })
  
  export const createUniversitySuccess = data => ({
    type: CREATE_UNIVERSITY_SUCCESS,
    payload: { data },
  })
  
  // charts data
  export const createUniversityFail = data => ({
    type: CREATE_UNIVERSITY_FAIL,
    payload: { data },
  })

  
export const storeAdminDashboardData = (name, data) => ({
  type: STORE_ADMIN_DASHBOARD_DATA,
  payload: { name, data },
});
 