// MetisMenu
import MetisMenu from "metismenujs"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
const SidebarContent = props => {
  const { userrole } = useSelector(state => ({
    userrole: state.Login.userrole,
  }))
  console.log(`userrole`, userrole)
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    const initMenu = () => {
      const splitMain = pathName?.split("/")
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        const spitPath = items[i].pathname.split("/")
        if (
          pathName === items[i].pathname ||
          splitMain?.[1] === spitPath?.[1]
        ) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }
  const [arrowIcon, setArrowIcon] = useState(false)
  const [faqIcon, setFaqIcon] = useState(false)
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            {/* {userrole == "admin" && (
            )} */}
            {userrole == "admin" ? (
              <li>
                <Link to="/dashboard" className=" waves-effect">
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Dashboard ")}</span>
                </Link>
              </li>
            ) : (
              ""
            )}

            {userrole == "admin" ? (
              <React.Fragment>
                <li>
                  <Link to="/packages" className="">
                    <i className="bx bx-layout"></i>
                    <span>{props.t("Packages")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="">
                    <i className="bx bx-paper-plane"></i>
                    <span>{props.t("Services")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/courses" className="">
                    <i className="bx bx-paper-plane"></i>
                    <span>{props.t("Course")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/coupon" className="">
                    <i className="bx bxs-coupon"></i>
                    <span>{props.t("Coupon")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/course-type" className="">
                    <i className="bx bx-paper-plane"></i>
                    <span>{props.t("Course Type")}</span>
                  </Link>
                </li>
                <li
                  className="profile-arrow-icon"
                  onClick={() => setArrowIcon(!arrowIcon)}
                >
                  <Link to="/#" className="waves-effect">
                    <i className="bx bx-user-check"></i>
                    <span>{props.t("General Task")}</span>
                  </Link>
                  <div className="arrow-icon-show-hide">
                    {arrowIcon ? (
                      <i className="bx bx-chevron-up"></i>
                    ) : (
                      <i className="bx bx-chevron-down"></i>
                    )}
                  </div>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/teachers-list">
                        {props.t("Teachers List")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/students-list">
                        {props.t("Students List")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/user-list">
                        {props.t("Students List (Excel)")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/author-list" className="">
                    <i className="fas fa-user-circle"></i>
                    <span>{props.t("Author")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/blog-list" className="">
                    <i className="fas fa-user-circle"></i>
                    <span>{props.t("Blog")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/banner" className="">
                    <i className="bx bx-cog" />
                    <span>{props.t("Banner")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/universityList" className="">
                    <i className="bx bx-cog" />
                    <span>{props.t("University List")}</span>
                  </Link>
                </li>
                <li
                  className="profile-arrow-icon"
                  onClick={() => setArrowIcon(!arrowIcon)}
                >
                  <Link to="/#" className="waves-effect">
                    <i className="bx bx-user-check"></i>
                    <span>{props.t("Support")}</span>
                  </Link>
                  <div className="arrow-icon-show-hide">
                    {arrowIcon ? (
                      <i className="bx bx-chevron-up"></i>
                    ) : (
                      <i className="bx bx-chevron-down"></i>
                    )}
                  </div>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/chat-box">{props.t("Chat Box")}</Link>
                    </li>
                    {/* <li>
                      <Link to="/create-q-a">{props.t("Create Q/A")}</Link>
                    </li> */}
                    <li>
                      <Link to="/token-list">{props.t("Token List")}</Link>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <Link to="/profile" className="">
                    <i className="fas fa-user-circle"></i>
                    <span>{props.t("Profile")}</span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/settings" className="">
                    <i className="bx bx-cog" />
                    <span>{props.t("Settings")}</span>
                  </Link>
                </li>{" "} */}
                <li>
                  <Link to="/review-answer" className="">
                    <i className="bx bx-cog" />
                    <span>{props.t("Review Answer")}</span>
                  </Link>
                </li>{" "}
                <li>
                  <Link to="/order-list" className="">
                    <i className="bx bx-layout"></i>
                    <span>{props.t("Order List")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/exam-data-review" className="">
                    <i className="bx bx-cog" />
                    <span>{props.t("Exam Data Review")}</span>
                  </Link>
                </li>{" "} */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <li>
                  <Link to="/teacher-exam" className="">
                    <i className="bx bx-layout"></i>
                    <span>{props.t("Exam")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/facilitator" className="">
                    <i className="fas fa-user-circle"></i>
                    <span>{props.t("Facilitator")}</span>
                  </Link>
                </li>

                {/* <li>
                  <Link to="/profile" className="">
                    <i className="fas fa-user-circle"></i>
                    <span>{props.t("Profile")}</span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/settings" className="">
                    <i className="bx bx-cog" />
                    <span>{props.t("Settings")}</span>
                  </Link>
                </li>{" "} */}
              </React.Fragment>
            )}
            <li
              className="profile-arrow-icon"
              onClick={() => setFaqIcon(!faqIcon)}
            >
              <Link to="/#" className="waves-effect">
                <i className="bx bx-user-check"></i>
                <span>{props.t("FAQ")}</span>
              </Link>
              <div className="arrow-icon-show-hide">
                {faqIcon ? (
                  <i className="bx bx-chevron-up"></i>
                ) : (
                  <i className="bx bx-chevron-down"></i>
                )}
              </div>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/faqType">{props.t("Faq Type")}</Link>
                </li>
                <li>
                  <Link to="/faqList">{props.t("Faq List")}</Link>
                </li>
              </ul>
              <li>
                  <Link to="/screen" className="">
                    <i className="bx bx-cog" />
                    <span>{props.t("Splash screen")}</span>
                  </Link>
                </li>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}
SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(SidebarContent))
