import * as Yup from "yup"

const mcqSchema = Yup.object({
  q: Yup.string().required("This value is Required"),
  opt: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  answer: Yup.string()
    // .oneOf([Yup.ref("opt")], "Answer is not matched with options")
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

const multipleQuesSchema = Yup.object({
  q: Yup.string().required("This value is Required"),
  opt: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  answer: Yup.array()
    .of(Yup.string().required("This value is Required"))
    // .oneOf([Yup.ref("opt")], "Answer is not matched with options")
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

const trueFalseSchema = Yup.object({
  q: Yup.string().required("This value is Required"),
  answer: Yup.string().required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

const fillInTheBlanksWithOption = Yup.object({
  q: Yup.string().required("This value is Required"),
  answer: Yup.array()
    .of(Yup.string().required("This value is Required"))
    // .oneOf([Yup.ref("opt")], "Answer is not matched with options")
    .required("This value is Required"),
  option: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

const fillInTheBlanksWith = Yup.object({
  q: Yup.string().required("This value is Required"),
  answer: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

const fillInTheBlanksListening = Yup.object({
  q: Yup.string(),
  answer: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

const shortAnswer = Yup.object({
  q: Yup.string(),
  answer: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

const headingMatchingSchema = Yup.object({
  q: Yup.string().required("This value is Required"),
  answer: Yup.string()
    // .oneOf([Yup.ref("options")], "Answer is not matched with options")
    .required("This value is Required"),
  options: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

export {
  mcqSchema,
  multipleQuesSchema,
  trueFalseSchema,
  fillInTheBlanksWithOption,
  fillInTheBlanksWith,
  headingMatchingSchema,
  fillInTheBlanksListening,
  shortAnswer,
}
