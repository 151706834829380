import { all, fork } from "redux-saga/effects"
import authSaga from "./auth/login/saga"
import ProfileSaga from "./auth/profile/saga"
import updatePasswordSaga from "./auth/updatePassword/saga"
import couponSaga from "./Coupon/saga"
import courseSaga from "./Course/saga"
import courseTypeSaga from "./CourseType/saga"
import dashboardSaga from "./dashboard/saga"
import LayoutSaga from "./layout/saga"
import listeningSaga from "./Listening/saga"
import listeningQuesSaga from "./ListeningQuestion/saga"
import pacakgeSaga from "./package/saga"
import readingQuesSaga from "./Reading/IELTS/Question/saga"
import readingSaga from "./Reading/IELTS/saga"
import ReviewAnswerSaga from "./ReviewAnswer/saga"
import ServiceSaga from "./service/saga"
import speakingSaga from "./Speaking/saga"
import speakingQuesSet from "./SpeakingQuestion/saga"
import studentSaga from "./student/saga"
import teacherSaga from "./teacher/saga"
import writingSaga from "./Writting/saga"
import facilitatorSaga from "./Facilitator/saga"
import ResultSaga from "./Result/saga"
import FacilitatorPackageSaga from "./PackageResult/saga"
import FacilitatorQuestionSaga from "./Question/saga"
import blogSaga from "./Blog/saga"
import BannerSaga from "./Banner/saga"
import AdminDashboardSaga from './AdminDashboard/saga'
import FaqSaga from './FAQ/saga'
import ChatSaga from "./Chat/saga"
import ScreenSaga from "./Splash/saga"
export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(pacakgeSaga),
    fork(teacherSaga),
    fork(ServiceSaga),
    fork(studentSaga),
    fork(readingSaga),
    fork(writingSaga),
    fork(listeningSaga),
    fork(readingQuesSaga),
    fork(courseSaga),
    fork(courseTypeSaga),
    fork(listeningQuesSaga),
    fork(ReviewAnswerSaga),
    fork(speakingSaga),
    fork(speakingQuesSet),
    fork(facilitatorSaga),
    fork(ResultSaga),
    fork(FacilitatorPackageSaga),
    fork(FacilitatorQuestionSaga),
    fork(couponSaga),
    fork(updatePasswordSaga),
    fork(blogSaga),
    fork(BannerSaga),
    fork(AdminDashboardSaga),
    fork(FaqSaga),
    fork(ChatSaga),
    fork(ScreenSaga),
    
  ])
}
